.intro {
  position: relative;
  display: block;
  min-height: 500px;

  picture {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
    z-index: 0;

    img {
      object-fit: cover;
      min-width: 100%;
    }

  }

  h2,
  .next-event {
    z-index: 1;
  }

  h2 {
    position: absolute;
    bottom: 10%;
    left: 10%;
    font-family: $body-font-family;
    font-weight: 700;
    color: #FFF;
    line-height: 1em;
  }

  .next-event {
    position: absolute;
    bottom: 10%;
    right: 10%;
    font-family: $body-font-family;
    font-weight: 700;
    color: #FFF;
    padding: 0 8px;
    border-right: 5px solid $secondary-color;

    h3 {
      padding: 0;
      margin: 0 0 8px;
      font-size: 1.2em;
      text-align: right;
    }

    p {
      margin: 0;
      padding: 0;
    }
  }
}

.about {
  padding: 150px 30px;
  position: relative;

  h2 {
    position: relative;
    z-index: 1;
    font-size: 60px;
    color: $secondary-color;
    font-family: $header-font-family;
    line-height: 1.2em;

    span {
      padding-top: 15px;
      font-size: .5em;
      color: $primary-color;
      line-height: 1em;
      display: block;
    }
  }

  picture {
    z-index: 0;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 70%;
    overflow: hidden;

    img {
      object-position: top;
    }
  }

  a {
    display: inline-block;
    padding-top: 12px;
    font-size: 1.4em;
    font-weight: 700;
    color: $secondary-color;
    border-bottom: 3px solid $secondary-color;
  }
}

.alternates {
  background: $secondary-bg-color;

  &.first {

    p {
      font-size: 1.4em;
      padding: 120px 120px 80px 70px;
    }

    position: relative;

    figure {

      position: relative;

      .swing {
        position: absolute;
        bottom: 0;
        height: 80%;
        width: auto;
        z-index: 1;
        transform: translateX(-15%);
      }

      .heart {
        position: absolute;
        left: 0;
        display: block;
        margin: auto;
        bottom: -50px;
        right: 0;
        height: 100px;
        z-index: 2;
      }

    }
  }

  &.second {
    background: #FFF;


    p {
      font-size: 1.4em;
      margin: 60px 60px 80px 70px;
    }

    picture {
      background: $secondary-bg-color;
      display: flex;
      width: 100%;
      height: 100%;
      overflow: hidden;

      img {
        object-fit: cover;
        min-height: 100%;
      }
    }
  }
}

.goals {
  position: relative;

  h2 {
    position: absolute;
    top: 40%;
    left: 0;
    right: 0;
    text-align: center;
    pointer-events: none;
    font-family: $header-font-family;
    font-size: 5em;
    color: $secondary-color;
  }

  a {
    display: block;
    font-size: 3em;
    color: $primary-color;
    text-align: center;
    padding: 3em 20px;
    font-weight: 700;
    transition: .4s ease-in;

    &:hover {
      background: $secondary-bg-color;
      color: $secondary-color;
    }
  }
}

.help {
  background: $secondary-color;
  padding-top: 80px;

  h2,
  p {
    color: #FFF;
  }

  h2 {
    font-size: 3em;
    font-family: $header-font-family;
  }

  p {
    font-size: 24px;
  }


  .button {

    font-size: 1.4em;
    line-height: 30px;
    font-family: $header-font-family;
    background-image: url("/assets/img/donate\ button\ raised.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent;
    width: 250px;
    height: 80px;
    transition: .5s all ease-out;

    &:hover {
      opacity: .7;
    }
  }
}

.newsandevents {
  background: $bg-color;
  padding: 50px 0;

  h2 {
    font-size: 3em;
    color: $secondary-color;
    font-family: $header-font-family;
    margin: 0 40px;
    padding-bottom: 25px;
  }

  .eventlist,
  .newslist {
    background: #FFF;
    list-style: none;
    padding: 20px;
    border-bottom: 5px solid $secondary-color;
    overflow: scroll;
    height: 450px;
    margin: 0 40px;
    box-shadow: 10px 10px 30px -10px rgba(0, 0, 0, 0.5);

    li {
      padding: 20px 30px;
      border-bottom: 1px solid $bg-color;

      a {
        span {
          color: $secondary-color;
          font-size: 1.2em;
        }
      }
    }
  }
}

@include breakpoint(medium down) {
  .intro {
    margin-top: 90px;

    h2 {
      top: 20%;
    }
  }

  .about {
    padding: 50px 30px;

    h2 {
      font-size: 2em
    }

    h3 {
      font-size: 1.6em;
    }
  }

  .alternates {
    &.first {
      p {
        padding: 30px 30px;
      }

      figure {

        .swing {
          position: absolute;
          bottom: 0;
          height: 80%;
          width: auto;
          z-index: 1;
          transform: translateX(0);
        }

        .heart {
          bottom: -30px;
          height: 60px;
        }

      }

    }

    &.second {
      p {
        padding: 30px;
      }
    }
  }

  .goals {
    position: relative;

    h2 {
      position: relative;
      font-size: 2em;
    }

    a {
      font-size: 1.6em;
      padding: 1em 20px;
    }
  }

  .help {
    padding-top: 40px;

    h2 {
      font-size: 2em;
      text-align: center;
    }

    p {
      text-align: center;
      font-size: 20px;
    }


    .button {
      margin: auto;
      display: block;
      margin-bottom: 30px;
    }
  }

  .newsandevents {
    background: $bg-color;
    padding: 50px 0;

    h2 {
      font-size: 2em;
      padding: 25px 0;
    }

    .eventlist,
    .newslist {
      list-style: none;
      overflow: auto;
      height: auto;
      margin: 0;
    }
  }
}