.post {
  display: block;
  padding-bottom: 30px;
  border-bottom: 2px solid $bg-color;
  margin-bottom: 30px;

  .date {
    display: block;
    padding-bottom: 8px;
    font-style: italic;
  }

  h2 {
    line-height: 1.2em;
  }
}