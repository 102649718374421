$foundation-palette: (
  primary: #1d1645,
  secondary: #f95050,
  success: #b867db,
  warning: #ffae00,
  alert: #cc4b37,
) !default;

$bg-color: #F6F4F9;
$secondary-bg-color: #dfdae1;

$footer-height: 275px;
$nav-height: 90px;

@font-face {
  font-family: YoungSerif;
  src: url("../assets/fonts/YoungSerif-Regular.otf") format("opentype");
}

$header-font-family: "YoungSerif", serif;
$body-font-family: europa, sans-serif;
