.event-full {

  h1 {
    padding-top: 25px;
  }
  p, ul {
    font-size: 22px;
  }

  .button {
    font-family: $header-font-family;
    font-size: 1.4em;
  }

  .sponsorship {
    background: $bg-color;
    margin-bottom: 20px;
    border: 1px solid $secondary-bg-color;

    h3 {
      color: $primary-color;
      background: $secondary-bg-color;
      padding: 10px 15px;
    }
    .button {
      font-family: $header-font-family;
      font-size: 1.4em;
      width: 100%;
      display: block;
      text-align: center;
      margin: 0;
    }

    ul {
      padding: 20px;
    }
  }
}

.event {
  background: $bg-color;
  border:1px solid $secondary-bg-color;
  border-bottom: 5px solid $secondary-color;
  display: block;
  margin-bottom: 40px;
  transform: translateY(0);
  transition: .5s all ease-out;

  h2, p {
    padding-left: 20px;
    padding-right: 20px;
  }

  &:hover {
    transform: translateY(-10px);
  }
}