.top-nav {
  background: $primary-color;
  padding: 0 40px;

  .logo {
    margin: $nav-height* .3 0;
    height: $nav-height *.4;
  }

  .nav-icon {
    margin-top: 25px;
    width: 40px;
    float: right;
    display: none;
    z-index: 100;

    &:hover:before, &:hover:after, &:hover div {
      background-color: $success-color;
    }

  }

  &.down .nav-icon {
    &:before {
      transform: translateY(12px) rotate(135deg);
    }
    
    &:after {
      transform: translateY(-12px) rotate(-135deg);
    }
    
    div {
      transform: scale(0);
    }
  }
  
  .nav-icon:after, 
  .nav-icon:before, 
  .nav-icon div {
    background-color: #fff;
    border-radius: 3px;
    content: '';
    display: block;
    height: 5px;
    margin: 7px 0;
    transition: all .2s ease-in-out;
  }

  ul {
    list-style: none;
    margin: 0;
    float: right;
    li {
      display: inline-block;
      a {
        display: block;
        color: #FFF;
        font-weight: 700;
        line-height: $nav-height;
        padding: 0 15px;
        transition: .4s all ease-out;

        span {
          display: none;
        }

        svg {
          height: 20px;
          width: 20px;
          display: block;
          cursor: pointer;

          .cls-1 {
            transition: .4s all ease-out;
          }
        }

        &:hover {
          color: $success-color;
          
          svg .cls-1 {
            fill: $success-color;
          }
        }
      }
    }
  }
}

footer {
  background: $primary-color;
  p {
    color: #FFF;
    text-align: center;
    margin: 0;
    font-size: 1.4em;
    padding: 70px 20px;
  }
}

@include breakpoint(medium down) {
  .top-nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 98;

    .nav-icon {
      display: inline-block;
    }

    &.down {
      ul {
        display: block;
      }
    }

    ul {
      display: none;
      position: absolute;
      background: $primary-color;
      top: $nav-height;
      z-index: 99;
      width: 100%;
      left: 0;


      li {
        display: block;
        width: 100%;
        padding: 0 10px;
        a {
          height: 60px;
          line-height: 60px;
          border-bottom: 2px solid darken($primary-color, 10%);
          display: flex;
          display: -webkit-flex; /* Safari */  
          -webkit-align-items: center; /* Safari 7.0+ */
          align-items: center;
          justify-content: center;
          span {
            padding-left: 10px;
            display: inline;
          }
          
        }
      }
    }
  }

  footer {
    p {
      font-size: 1.1em;
      padding: 70px 20px;
    }
  }
}